.wrapper {
    display: flex;
    flex-direction: column;
    width: 700px;
    margin: 0 auto;
    background-color: #eee;
    padding: 20px 40px;

    h1 {
        text-align: center;
        font-size: 42px;
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 24px 0;

        h3 {
            color: #000;
            font-size: 32px;
            line-height: 40px;
        }

        button {
            color: #fff;
            background-color: #55f;
            border-radius: 4px;
            padding: 8px 22px;

            &:disabled {
                opacity: .7;
                cursor: not-allowed;
            }
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-bottom: 24px;

        &-inputs {
            display: flex;
            column-gap: 20px;

            .input {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                label {
                    font-size: 14px;
                    margin-bottom: 4px;
                }

                input {
                    margin: 0;
                    background-color: #fff;
                    border-radius: 4px;
                    border: 1px solid #ddd;
                    font-size: 14px;
                    line-height: 18px;
                    padding: 6px 12px;
                }
            }
        }
    }

    .search {
        margin-bottom: 16px;
        position: relative;

        img {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
        }

        input {
            width: 100%;
            background-color: #fff;
            border-radius: 4px;
            border: 1px solid #ddd;
            font-size: 14px;
            line-height: 18px;
            padding: 6px 12px 6px 26px;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            border-bottom: 1px solid #ddd;

            &:last-of-type {
                border: none;
            }

            .col {
                display: flex;
                flex-direction: column;
                row-gap: 4px;

                .name {
                    font-weight: 700;
                }
            }

            button {
                width: 30px;
                height: 30px;
                border-radius: 4px;
                background-color: #ddd;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .empty {
        font-size: 18px;
        padding: 0 4px;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        column-gap: 20px;

        button {
            color: #fff;
            padding: 8px 28px;
            border-radius: 4px;
            background-color: #55f;

            &:disabled {
                opacity: .7;
                cursor: not-allowed;
            }

            &.cancel {
                background-color: #ff3535;
            }
        }
    }
}